<!--
 * @Date: 2021-03-22 17:32:30
 * @LastEditors: frank
 * @LastEditTime: 2021-05-11 13:29:48
 * @FilePath: /shop_frontend/src/components/photoList.vue
 * @Description: Description
-->
<template>
  <section class="photoList">
    <a-modal title="浏览图片" :visible="visible" @ok="handleOk" @cancel="handleCancel" width="60%">
      <div>
        <!-- <a-alert
          message="上传图片支持jpeg、jpg、bmp、gif格式，为保障前端加载顺利，单张图片大小不能超过5M"
          type="warning"
          style="margin: 20px 0"
        /> -->
        <a-row>
          <!-- <a-col span="4">
            <ul>
              <li>用户图片</li>
              <li>我的图片</li>
            </ul>
          </a-col> -->
          <a-col span="24">
            <a-row>
              <a-col span="6">
                <a-upload
                  name="image"
                  action="/api/goods_pictures/"
                  :withCredentials="true"
                  :headers="headers"
                  @change="uploadChange"
                  :multiple="true"
                  :showUploadList="false"
                  :beforeUpload="beforeUpload"
                >
                  <div>
                    <a-button type="primary"><a-icon type="plus" />上传图片</a-button>
                  </div>
                </a-upload>
              </a-col>
              <a-col span="18" style="display: flex; align-items: center">
                <a-select style="width: 220px" v-model="ordering">
                  <a-select-option value="name"> 按名称正序 </a-select-option>
                  <a-select-option value="-name"> 按名称倒序 </a-select-option>
                  <a-select-option value="create_time">
                    按创建时间正序
                  </a-select-option>
                  <a-select-option value="-create_time">
                    按创建时间倒序
                  </a-select-option>
                </a-select>

                <a-input class="w220" style="margin: 0 20px" v-model="search" allowClear></a-input>
                <a-button @click="onSearch">搜索</a-button>
              </a-col>
            </a-row>
            <div class="imgBox">
              <div v-for="el in imgList" :key="el.id" class="img__show" @click.stop="onChecked(el)">
                <img :src="el.image" alt="" />
                <span class="delBtn" @click.self.stop="delImg(el)">删除</span>
                <span class="enlargeBtn" @click.self.stop="handleEnlargeImage(el.image)">放大</span>

                <span class="name">{{ el.name || "" }}</span>
              </div>
            </div>
            <a-pagination v-bind="pagination" @change="pageChange" />
          </a-col>
        </a-row>
        <div class="checked__box">
          <div class="checked__box_title">已选中{{ checkedList.length || 0 }}张图片</div>
          <div class="imgBox">
            <div v-for="(el, index) in checkedList" :key="el.id" class="img__show">
              <img :src="el.image" alt="" />
              <span class="delBtn" @click.stop="delCheckedImg(el, index)">删除</span>
              <span class="enlargeBtn" @click.stop="handleEnlargeImage(el.image)">放大</span>
            </div>
          </div>
        </div>
      </div>
    </a-modal>

    <a-modal v-model="imageVisible" :closable="false" :footer="null">
      <div>
        <img :src="enlargeImage" alt="" style="width:100%;" />
      </div>
    </a-modal>
  </section>
</template>

<script>
import Cookies from "js-cookie";
export default {
  name: "photoList",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      imgList: [],
      search: "",
      ordering: "-create_time",
      page: 1,
      checkedList: [],
      pagination: {
        total: 0,
        showTotal: (total, range) => {
          return `共 ${total} 条`;
        },
        showQuickJumper: true,
        defaultPageSize: 15,
      },

      imageVisible: false,
      enlargeImage: undefined,
    };
  },
  components: {},
  computed: {
    headers() {
      return { "X-CSRFToken": Cookies.get("csrftoken") };
    },
    params() {
      return {
        page: this.page,
        search: this.search,
        ordering: this.ordering,
        page_size: 12,
      };
    },
  },
  watch: {
    visible(nVal) {
      if (nVal) {
        this.checkedList = [];
        this.search = "";
        this.ordering = "-create_time";
        this.page = 1;
        this.getImg();
      }
    },
  },
  created() {
    this.getImg();
  },
  mounted() {},
  methods: {
    handleEnlargeImage(enlargeImage) {
      this.enlargeImage = enlargeImage;
      this.imageVisible = true;
    },
    delCheckedImg(el, index) {
      this.checkedList.splice(index, 1);
    },
    onSearch() {
      this.page = 1;
      this.getImg();
    },
    handleOk() {
      this.$emit("ok", this.checkedList);
    },
    handleCancel() {
      this.$emit("cancel");
    },
    uploadChange({ file }) {
      console.log(file);
      if (file.status === "done") {
        this.getImg();
      }
    },
    pageChange(page) {
      this.page = page;
      this.getImg();
    },
    getImg() {
      this.$axios.get("/goods_pictures/", { params: this.params }).then((res) => {
        console.log(res);
        this.imgList = res.results || [];
        this.pagination.total = res.count || 0;
      });
    },
    delImg(obj) {
      this.$axios.delete(`/goods_pictures/${obj.id}/`).then((res) => {
        this.$message.success("操作成功");
        this.getImg();
      });
    },
    onChecked(el) {
      if (this.multiple) {
        this.checkedList.push(el);
      } else {
        this.checkedList = [el];
      }
    },
    beforeUpload(file, fileList) {
      const isLt500Kb = file.size / 1024 < 2048;

      if (!isLt500Kb) {
        this.$message.error(file.name + "文件大小超出限制(2048KB)，请修改后重新上传");
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped lang="less">
.imgBox {
  padding: 20px 0;
}
.img__show {
  display: inline-block;
  width: 120px;
  height: 120px;
  margin-right: 20px;
  margin-bottom: 40px;
  cursor: pointer;
  position: relative;
  > img {
    width: 100%;
    height: 100%;
    display: inline-block;
  }
  .delBtn {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 20px;
    line-height: 20px;
    color: red;
    text-align: center;
    /* padding: 0 20px; */
    font-size: 10px;
    background: rgba(0, 0, 0, 0.3);
  }
  .enlargeBtn {
    position: absolute;
    left: 50%;
    top: 0;
    width: 50%;
    height: 20px;
    line-height: 20px;
    color: red;
    text-align: center;
    /* padding: 0 20px; */
    font-size: 10px;
    background: rgba(0, 0, 0, 0.3);
  }
  .name {
    position: absolute;
    left: 0;
    bottom: -20px;
    width: 100%;
    height: 20px;
    line-height: 20px;
    color: #000000;
    text-align: center;
    padding: 0 20px;
    font-size: 10px;
    background: rgba(0, 0, 0, 0.3);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.checked__box {
  border: 1px solid #ddd;
  min-height: 80px;
  margin: 20px 0;
  .checked__box_title {
    background: #ddd;
    opacity: 0.7;
    padding: 10px;
  }
}
</style>
